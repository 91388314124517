<template>
  <div class="dse-text-area" :class="computedClasses">
    <el-input
      ref="textarea"
      v-model="currentValue"
      type="textarea"
      :autosize="{ minRows, maxRows }"
      :placeholder="placeholder ? $t(placeholder) : ''"
      :disabled="disabled"
      :maxlength="maxlength"
      :minlength="minlength"
      :show-word-limit="showWordLimit"
      :clearable="clearable"
      @focus="handleFocus"
      @blur="handleBlur"
      @input="handleInput"
    >
    </el-input>
  </div>
</template>

<script>
export default {
  props: {
    value: String,
    minRows: {
      type: Number,
      default: 3
    },
    maxRows: {
      type: Number,
      default: 6
    },
    placeholder: {
      type: String,
      default: 'textArea.placeholder'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    maxlength: {
      type: Number,
      default: 300
    },
    minlength: {
      type: Number,
      default: 0
    },
    showWordLimit: {
      type: Boolean,
      default: true
    },
    clearable: Boolean
  },
  data() {
    return {
      currentValue: this.value,
      hasFocus: false
    };
  },
  computed: {
    computedClasses() {
      return { 'has-focus': this.hasFocus };
    }
  },
  watch: {
    value(value) {
      this.currentValue = this.value;
    }
  },
  methods: {
    handleInput(value) {
      this.$emit('input', value);
    },
    handleFocus() {
      this.hasFocus = true;
    },
    handleBlur() {
      this.hasFocus = false;
    },
    focus() {
      this.$refs.textarea.focus();
    }
  }
};
</script>

<style lang="scss">
.dse-text-area {
  .el-textarea .el-input__count {
    display: none;
    color: #909399;
    background: #fff;
    position: absolute;
    font-size: 12px;
    bottom: 5px;
    right: 10px;
  }
  &.has-focus {
    .el-textarea .el-input__count {
      display: block;
    }
  }
}
</style>
