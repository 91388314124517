import MDinput from '@/components/MDinput';
import DSESelect from '@/components/DSE/DSESelect';
import { DatePicker } from 'element-ui';
import { validatorRequire } from '@/utils/validators';
import TextArea from '@/components/DSE/TextArea';

export function getFormDefinition(vue) {
  return {
    dossierID: {
      type: MDinput,
      props: {
        readonly: true
      }
    },
    actionTypeID: {
      type: DSESelect,
      props: {
        caption: 'dossierAction.actionTypeName',
        selectFirstOnDefault: true,
        required: true,
        valueField: 'id',
        labelField: 'name',
        loading: vue.$store.getters['actionType/isLoading'],
        options: vue.$store.getters['actionType/loadedItems']
      },
      rules: [{ validator: validatorRequire }]
    },
    comment: {
      type: TextArea,
      defaultValue: '',
      props: {
        caption: 'dossierAction.comment',
        maxlength: 1000
      },
      valueOnFormChange(form, currentValue, changedKeys) {
        if (!vue.isEdit && changedKeys.includes('actionTypeID')) {
          const selectedActionTypeId = +form.actionTypeID;
          const actionType = vue.$store.getters['actionType/getLoadedItemByID'](selectedActionTypeId);
          if (actionType && actionType.defaultComment) {
            return actionType.defaultComment;
          } else {
            return null;
          }
        }
        return currentValue;
      }
    },
    actionStatusID: {
      type: DSESelect,
      props: {
        caption: 'dossierAction.actionStatusName',
        selectFirstOnDefault: true,
        required: true,
        valueField: 'id',
        labelField: 'name',
        loading: vue.$store.getters['actionStatus/isLoading'],
        options: vue.$store.getters['actionStatus/loadedItems']
      },
      rules: [{ validator: validatorRequire }]
    },
    creationDate: {
      type: DatePicker,
      defaultValue: vue.dateStampToday,
      props: {
        caption: 'dossierAction.creationDate',
        required: true,
        format: vue.$store.getters['dateFormat']
      },
      rules: [{ validator: validatorRequire }]
    },
    alertDate: {
      type: DatePicker,
      props: {
        caption: 'dossierAction.alertDate',
        required: true,
        format: vue.$store.getters['dateFormat']
      },
      rules: [{ validator: validatorRequire }]
    },
    actingRoleID: {
      type: DSESelect,
      props: {
        caption: 'dossierAction.actingRoleName',
        selectFirstOnDefault: true,
        required: true,
        valueField: 'id',
        labelField: 'name',
        options: vue.$store.getters['role/loadedItems'],
        loading: vue.$store.getters['role/isLoading']
      },
      rules: [{ validator: validatorRequire }]
    },
    loanID: {
      type: DSESelect,
      props: {
        caption: 'loan.actNumber',
        selectFirstOnDefault: false,
        required: false,
        valueField: 'id',
        labelField: 'id',
        options: vue.$store.getters['loan/getLoansByDossierId'](vue.dossierID || vue.dossier.dossierID),
        loading: vue.$store.getters['loan/isLoading']
      }
    }
  };
}

export default getFormDefinition;

export function createItemFromFormData(vue, form) {
  return form;
}

export function createFormDataFromItem(vue, item) {
  const form = {
    ...item
  };
  return form;
}

export function getDossierInfoById(vue) {
  const dossierId = vue.dossier.dossierID;
  const dossierinformation = vue.$store.getters['dossier/DossierInformation'](dossierId);
  return {
    options: dossierinformation
  };
}
